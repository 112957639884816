@import './variables';

.Toastify__toast {
  box-shadow: none;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: $login-text-success-color;
  background: $login-success-color;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: $alert-text-warning-color;
  background: $alert-warning-color;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: $alert-text-errors-color;
  background: $alert-errors-color;
}

.Toastify__toast-theme--colored.Toastify__toast--default {
  color: $alert-text-info-color;
  background: $alert-info-color;
}
