.slider {
  position: relative;

  .slider__arrow {
    position: absolute;
    left: -16px;
    top: 50%;
    transform: translateY(-50%);
    background: $gray-500;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    border-radius: $border-radius-full;
    z-index: 1;
    width: 25px;
    height: 25px;

    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &.slider__arrow-right {
      left: auto;
      right: -16px;
    }
  }

  .slider__dots {
    display: flex;

    .slider__dot {
      + .slider__dot {
        margin-left: 4px;
      }

      button {
        background: $gray-300;
        border: 1px solid $gray-300;
        border-radius: $border-radius-full;
        width: 10px;
        height: 10px;
        opacity: 0.2;
      }

      &.slider__dot_active {
        button {
          opacity: 1;
          background: $gray-400;
          pointer-events: none;
        }
      }
    }
  }

  .slider__items {
    display: flex;
    overflow: auto hidden;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .slider__item {
    scroll-snap-align: start;
    flex: 0 0 auto;
  }
}

.swiper-pagination-bullet-active {
  background: $primary;
}
