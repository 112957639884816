.search {
  min-height: 49px;

  @include media(sm) {
    position: relative;
  }

  label {
    position: relative;
    display: block;

    .icon {
      font-size: 28px;
      position: absolute;
      left: 10px;
      top: 11px;
    }

    input {
      border-radius: $border-radius;
      background: $gray-100;
      padding: 13px 0;
      padding-left: 40px;
      width: 100%;

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 2px $gray-500;
      }

      &::placeholder {
        font-size: 15px;
      }
    }
  }

  .search__results {
    $form-width: 380px;

    position: absolute;
    display: flex;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    max-width: $form-width;
    padding: 16px;
    padding-right: 0;
    background: white;
    z-index: 20;
    left: 50%;
    transform: translateX(calc(-50% - 16px));
    margin: 5px 16px 16px;
    width: calc(100% - (16px * 2));
    max-height: calc(100vh - 110px);

    @include media(sm) {
      width: 100%;
      min-width: 290px;
      max-width: none;
      margin-left: 0;
      margin-right: 0;
      left: 0;
      transform: none;
    }
  }

  .search__results-body {
    padding-right: 16px;
    overflow-y: auto;
    width: 100%;
  }

  .search__group {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .search__subtitle {
    padding-bottom: 6px;
    font-weight: 700;
    font-size: 13px;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background: white;
    z-index: 1;
  }

  .search__item {
    display: flex;
    align-items: center;
    padding: 6px 0;

    + .search__item {
      border-top: 1px solid $gray-200;
    }

    .search__item-image {
      overflow: hidden;
      // background: $gray-100;
      border-radius: 4px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      flex-shrink: 0;

      .icon {
        font-size: $text-2xl;
        color: $gray-300;
      }
    }

    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    small {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
