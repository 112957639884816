.city__banner {
  background: $white;

  .container {
    position: relative;
    padding-top: 40px;
    padding-bottom: 0px;
  }

  figure {
    position: relative;
    height: 300px;
  }

  img {
    border-radius: $border-radius-md;
  }

  .heading.heading_level-1 {
    font-weight: 700;
  }
}

.city__information {
  @extend .container;

  padding-top: 27px;
  padding-bottom: 47px;
}

.information__text {
  margin-top: 16px;
  margin-bottom: 25px;
  line-height: normal;
}

.splash__reviews {
  padding: 67px 0px;
}
