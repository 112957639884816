.categories_container {
  display: flex;
  flex-direction: column;

  @include media(sm) {
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
  }
}

.categories {
  // @extend .container;
  background: #f5f5f5;
  position: relative;

  .container {
    padding-top: 40px;
    padding-bottom: 48px;

    @include media(md) {
      padding-top: 64px;
      padding-bottom: 64px;
    }
  }

  .heading.heading_level-2 {
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  .heading__description {
    font-size: $text-lg;
    margin-bottom: 48px;
  }

  .slider__arrow {
    left: 8px;

    &.slider__arrow-right {
      right: 8px;
    }

    &:disabled {
      display: none;
    }

    @include media(sm) {
      left: -13px;

      &.slider__arrow-right {
        right: -13px;
      }
    }
  }

  .slider {
    margin: 0 -16px;

    @include media(sm) {
      margin: 0;
    }
  }

  .slider__items {
    scroll-snap-type: none;
    margin-top: -20px;
    padding: 20px 16px 0;

    @include media(sm) {
      scroll-snap-type: x mandatory;
      padding: 20px 0 0;
    }
  }

  .slider__item:not(:last-child) .category {
    margin-right: 15px;
  }

  .category {
    // display: grid;
    // grid-template-rows: 192px auto;
    // grid-template-columns: 162.5px;
    display: flex;
    flex-direction: column;
    width: 162.16px;
    height: 219px;

    // @include media(sm) {
    //     width: 110px;
    // }

    &:hover {
      .category__label {
        color: $gray-500;
      }
    }
  }

  .category__icon {
    width: 100%;
    height: 100%;
    background: $white;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 8px 24px rgb(18 37 33 / 13%);
    overflow: hidden;
  }

  .category__label {
    font-size: $text-base;
    letter-spacing: 0.2px;
    font-weight: 400;
    margin-top: 5px;
    padding-left: 5px;
    transition: $transition-all;
    text-align: left;
  }
}
