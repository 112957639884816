.checkout {
  background-color: var(--bg-color);
  min-height: 586px;
  padding-block: 40px;

  @include media(md) {
    padding-bottom: 70px;
  }

  &_sign_in {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    height: calc(100vh - 310px);

    @media screen and (min-width: 572px) {
      height: calc(100vh - 270px);
    }

    @include media(sm) {
      height: calc(100vh - 354px);
    }

    &__container {
      display: flex;
      align-items: center;
      gap: 24px;
      border-radius: 30px;
      border: 1px solid $account-message-border-color;
      padding: 24px;
      max-width: 450px;
      max-height: 116px;

      @media screen and (max-width: 450px) {
        flex-direction: column;
        padding: 24px 20px;
        margin: 0;
        max-width: 300px;
        max-height: 200px;
      }

      &_body {
        text-align: center;

        @media screen and (min-width: 450px) {
          text-align: left;
        }

        span {
          font-size: $text-xl;
          font-weight: 800;
          line-height: 26px;
        }

        p {
          font-size: $text-sm;
          margin-top: 4px;
          max-width: 200px;
          text-align: left;
          color: $account-message-heading-color !important;

          @media screen and (max-width: 400px) {
            text-align: left;
          }
        }
      }

      button {
        height: 36px;
        padding: 0px 30px !important;
        border-radius: 30px;
      }
    }
  }
  .checkout__link {
    font-weight: 700;
    transition: $transition-all;

    &:hover {
      color: $gray-300;
    }

    &:focus {
      outline: 0;
    }
  }

  .checkout__placeholders {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 24px;
    padding: 16px 16px 24px;

    .cart-placeholder,
    .summary-placeholder {
      background: #e8e8e8;
      border-radius: 8px;
      width: 100%;
    }

    .cart-placeholder {
      height: 50vh;
    }

    .summary-placeholder {
      height: 60vh;
    }

    @include media(lg) {
      margin-right: auto;
      margin-left: auto;
      padding: 32px;
      grid-template-columns: 1fr 30%;
      column-gap: 32px;
      align-items: flex-start;
      max-width: 1440px;
      height: 586px;

      .cart-placeholder,
      .summary-placeholder {
        height: 100%;
      }

      .cart-placeholder {
        flex: 7;
        margin-right: 2rem;
      }

      .summary-placeholder {
        flex: 3;
      }
    }
  }

  .checkout-error {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title {
      font-size: 2rem;
      font-weight: 700;
      margin-bottom: 1rem;
    }

    .subtitle {
      font-size: 1.2rem;
      font-weight: 400;
    }
  }
}
