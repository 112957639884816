.contact-page {
  @extend .container;

  margin-bottom: 40px;

  @include media(md) {
    display: grid;
    grid-template-columns: minmax(0, 400px) 1fr;
    gap: 70px;
    margin-bottom: 70px;
  }

  .heading.heading_level-2 {
    margin-top: 24px;
    margin-bottom: 48px;
  }

  .heading.heading_level-3 {
    font-weight: 600;
    margin-bottom: 16px;
  }

  .heading.heading_level-4 {
    color: $gray-500;
  }

  .contact__text {
    margin-bottom: 25px;
  }

  .contact__body {
    margin-bottom: 40px;

    @include media(md) {
      margin-bottom: 0;
    }
  }

  .map {
    height: 500px;
    border-radius: $border-radius;
    overflow: hidden;
    padding-left: 50px;

    @include media(md) {
      height: 100%;
    }
  }

  address {
    font-style: normal;
    grid-column: 1 / -1;

    > * + * {
      margin-top: 25px;
    }

    a {
      font-weight: 500;
      transition: $transition-all;

      &:not(.socials__item):hover {
        color: $gray-300;
      }
    }
  }

  .contact {
    margin-top: 40px;

    label {
      margin-top: 32px;

      span {
        font-weight: 600;
      }
      input {
        padding: 5px 12px;
      }
    }

    button {
      margin-top: 25px;
      width: 50%;
      display: block;
      text-transform: uppercase;
    }
  }
}
