.separator {
  display: flex;
  align-items: center;

  &::before,
  &::after {
    border-top: 1px solid $gray-200;
    display: block;
    width: 100%;
  }

  &::before {
    content: '';
  }

  &:not(:empty)::after {
    content: '';
  }

  .separator__label {
    white-space: nowrap;
    margin: 0 12px;
  }
}
