.search {
  &__page {
    position: relative;
    background-color: $background-theme;
  }

  &__breadcrumb {
    margin-top: 40px;
  }

  &__content {
    width: 100%;
    background: inherit;
    padding-bottom: 4rem;
  }

  &__custom_search_box {
    background: rgba(255, 255, 255, 0.2) !important;
    border: none !important;
    border-radius: $border-radius-md;
    left: 0;
    right: 0;
    top: 350px;
    z-index: 2;
    margin: 0 auto;
    padding: 20px 30px;
    position: absolute;
    user-select: none;
    width: 70vw;
    max-width: 628px;
    display: flex;
    align-items: center;
    height: 100px !important;

    &:focus-within {
      border: none !important;
    }

    &_loading {
      right: 2rem;
    }

    @media screen and (max-width: 420px) {
      width: 90vw;
    }

    @include media(sm) {
      padding: 34px 36px;
    }

    @include media(md) {
      width: 50vw;
    }

    input {
      position: relative;
      border: 1px solid $input-border-color !important;
      color: $black !important;
      padding: 0 45px 0 40px !important;
      background: $white !important;
      height: 36px;
      width: 100%;
    }

    input::placeholder {
      opacity: 50%;
      font-size: $text-sm;
      color: $input-placeholder-color !important;
    }

    input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      -webkit-user-modify: read-write !important;
      border-left: 1px solid $input-border-color;
      padding-left: 10px;
      width: 20px;
      font-size: 20px;
      position: absolute;
      top: 8px;
      right: 10px;
      content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4 4L20 20M20 4L4 20' stroke='%23070C15' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
      cursor: pointer;

      &:hover {
        content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M4 4L20 20M20 4L4 20' stroke='%23" + $input-icon-color-activate + "' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
      }
    }

    &:focus-within {
      input {
        border: 1px solid $input-border-color-activate !important;
        box-shadow: rgb(119 122 175 / 30%) 0 1px 4px 0 inset;
        outline: none;
      }

      input::placeholder {
        color: $input-placeholder-color-activate !important;
      }

      &::before {
        background: transparent
          url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.22218 1.66496C4.15301 1.66496 1.66496 4.15301 1.66496 7.22218C1.66496 10.2914 4.15301 12.7794 7.22218 12.7794C10.2914 12.7794 12.7794 10.2914 12.7794 7.22218C12.7794 4.15301 10.2914 1.66496 7.22218 1.66496ZM0.334961 7.22218C0.334961 3.41848 3.41848 0.334961 7.22218 0.334961C11.0259 0.334961 14.1094 3.41848 14.1094 7.22218C14.1094 11.0259 11.0259 14.1094 7.22218 14.1094C3.41848 14.1094 0.334961 11.0259 0.334961 7.22218Z' fill='%23" + $search-icon-color-focus + "'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4184 11.4187C11.6781 11.159 12.0992 11.159 12.3589 11.4187L15.47 14.5298C15.7297 14.7895 15.7297 15.2105 15.47 15.4702C15.2103 15.7299 14.7892 15.7299 14.5295 15.4702L11.4184 12.3591C11.1587 12.0994 11.1587 11.6783 11.4184 11.4187Z' fill='%23" + $search-icon-color-focus + "'/%3e%3c/svg%3e")
          repeat scroll 0% 0%;
      }
    }

    &::before {
      background: transparent
        url("data:image/svg+xml;charset=UTF-8,%3csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.22218 1.66496C4.15301 1.66496 1.66496 4.15301 1.66496 7.22218C1.66496 10.2914 4.15301 12.7794 7.22218 12.7794C10.2914 12.7794 12.7794 10.2914 12.7794 7.22218C12.7794 4.15301 10.2914 1.66496 7.22218 1.66496ZM0.334961 7.22218C0.334961 3.41848 3.41848 0.334961 7.22218 0.334961C11.0259 0.334961 14.1094 3.41848 14.1094 7.22218C14.1094 11.0259 11.0259 14.1094 7.22218 14.1094C3.41848 14.1094 0.334961 11.0259 0.334961 7.22218Z' fill='%23" + $search-icon-color + "'/%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.4184 11.4187C11.6781 11.159 12.0992 11.159 12.3589 11.4187L15.47 14.5298C15.7297 14.7895 15.7297 15.2105 15.47 15.4702C15.2103 15.7299 14.7892 15.7299 14.5295 15.4702L11.4184 12.3591C11.1587 12.0994 11.1587 11.6783 11.4184 11.4187Z' fill='%23" + $search-icon-color + "'/%3e%3c/svg%3e")
        repeat scroll 0% 0%;
      content: '';
      height: 1rem;
      left: 42px;
      position: absolute;
      top: 42px;
      width: 1rem;
      z-index: 1;

      @include media(sm) {
        left: 48px;
      }
    }

    span {
      color: $gray-300 !important;
    }
  }

  &__title {
    font-size: $text-4xl !important;
    line-height: 56px;
  }
}
