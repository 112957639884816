.nav {
  display: none;
  position: -webkit-sticky;
  position: sticky;
  top: 76px;
  border-bottom: 1px solid $gray-200;
  background: $white;
  z-index: 9;

  @include media(md) {
    display: block;
  }

  nav {
    @extend .container;

    overflow: auto hidden;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }

    &::after,
    &::before {
      content: '';
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      right: -17px;
      margin-right: -16px;
      display: block;
      background: linear-gradient(to left, white, transparent);
      flex: 0 0 16px;
    }

    &::before {
      background: linear-gradient(to right, white, transparent);
      right: auto;
      left: -16px;
      margin-right: 0;
      margin-left: -16px;
    }
  }

  .link {
    padding: 12px 0;
    font-size: 15px;
    flex-shrink: 0;

    &:hover {
      opacity: 0.8;
    }

    + .link {
      margin-left: 30px;
    }

    &.is-accent {
      font-weight: 700;
      color: $errors;
    }
  }
}
