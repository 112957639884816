@import './styles//variables';
@import './styles/mixin';

.slick-arrow {
  position: relative;
  color: $controls-slider;
  font-size: 0;
  line-height: 0;
  width: 52.99px;
  height: 42.65px;
  border: 1px solid $controls-border;

  &:focus {
    outline: 0;
  }

  &:hover {
    color: $controls-slider;
  }

  &::before {
    font-size: $text-xl;
  }

  &.slick-prev {
    position: relative;
    border: 1px solid $controls-border;
    width: 52.99px;
    border-right: 0;
    border-radius: $border-radius 0 0 $border-radius;

    &::before {
      @extend .icon, .icon_left;
    }

    &::after {
      content: '';
      position: absolute;
      top: 5px;
      right: 0;
      display: inline-block;
      border-right: 1px solid $controls-border;
      height: 70%;
    }
  }

  &.slick-next {
    position: relative;
    border-left: 0;
    border-radius: 0 $border-radius $border-radius 0;

    &::before {
      @extend .icon, .icon_right;
    }
  }

  &.slick-disabled {
    color: $controls-disabled;
    cursor: not-allowed;
  }
}
