.search-algolia {
  @extend .search;

  &:focus-within {
    label {
      width: calc(100% - 32px);
      position: absolute;
      top: 12px;
      left: 50%;
      z-index: 5;
      transform: translateX(-50%);

      @include media(sm) {
        width: auto;
        position: relative;
        top: 0;
        left: 0;
        transform: translateX(0);
      }
    }

    .search__results {
      top: 62px;

      @include media(sm) {
        top: auto;
      }
    }
  }

  .ais-SearchBox-reset,
  .ais-SearchBox-loadingIndicator {
    display: none;
  }

  .ais-Highlight-highlighted {
    background-color: $success;
    font-style: normal;
  }

  .ais-Hits-item {
    + .ais-Hits-item {
      border-top: 1px solid $gray-200;
    }
  }

  .search-algolia__powered-by {
    height: 15px;
    margin-top: 10px;
  }

  .search__hits {
    display: flex;
    flex-direction: column-reverse;

    &:not(:first-child) {
      .search__subtitle {
        margin-top: 10px;
      }
    }

    .ais-Hits {
      &.ais-Hits--empty {
        + .search__subtitle {
          display: none;
        }
      }
    }
  }
}
