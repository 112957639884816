.thanks {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6%;
  gap: 25px;
  background-image: url('/images/thanks/thank-you-page-mobile.svg');
  background-position: top;
  background-repeat: no-repeat;

  @include media(lg) {
    background-image: url('/images/thanks/thank-you-page-desktop.svg');
  }

  .orderId {
    display: flex;
    align-items: center;
    background-color: $gray-200;
    color: $secondary-400;
    padding: 8px 40px;
    border-radius: 20px;
  }

  .thanks_description {
    text-align: center;
    color: $gray-600;
    font-size: $text-2xl;
    max-width: 710px;
    line-height: 36px;
    font-weight: 400;
  }

  .button_continue-shopping {
    padding: 15px 50px;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .thanks_email_confirmation_text {
    @extend .thanks_description;
    font-style: italic;
    line-height: 30px;
    font-size: $text-xl;
    font-weight: 400;
  }

  .heading_centered_text {
    text-align: center;
    margin-top: 120px;
  }
}
.thanks-new__wrapper-container {
  margin-top: 50px;
}
