@import './mixin';

.page_not_found {
  user-select: none;

  &__content {
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &__description {
      font-size: $text-xs;
      line-height: 1.733;
      text-align: center;

      @include media(sm) {
        font-size: $text-base;
      }
    }

    .heading {
      //color: $not-found-page;
      background: $not-found-page-heading-gradient;
      -webkit-background-clip: text;
      -webkit-text-fill-color: $not-found-page;
      font-size: 10rem;
      line-height: 1;

      @include media(sm) {
        font-size: 16.25rem;
      }

      &__descrip {
        color: get-value($account-photo-username, $not-found-page-title-color);
        font-size: $text-xl;
        font-weight: 500;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        width: 100%;
        text-align: center;

        @include media(sm) {
          font-size: $text-3xl;
        }
      }
    }
  }

  &__heading {
    position:relative;
  }

  &__form {
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;

    .input_search {
      height: 45px;
      margin: 40px auto;
      width: 100%;
      max-width: 360px;

      @include media(sm) {
        height: 50px;
        align-self: center;
      }

      span {
        text-overflow: unset !important;
      }

      input::placeholder {
        color: $gray-500;
      }
    }

    &_action_return {
      margin-top: 20px;
    }
  }
}

.page_not_found::before {
  content: '';
  display: block;
  height: var(--header-height);
  background-color: $header-sticky-filled;
  width: 100%;
}
