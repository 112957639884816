.contact {
  .button {
    text-transform: uppercase;
    color: $gray-500;
    font-size: $text-sm;
    max-width: 252px;
    max-height: 36px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
}
