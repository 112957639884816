#nprogress {
  .bar {
    height: 5px;
    background: $progress-color;
  }

  .peg {
    box-shadow: 0 0 10px $progress-color, 0 0 5px $progress-color;
  }
}
