.store_locator {
  padding-bottom: 48px;
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 32px;
    row-gap: 1rem;

    @media screen and (max-width: 519px) {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    @media screen and (min-width: 519px) {
      display: flex;
      gap: 24px;
      flex-direction: column-reverse;
    }

    @include media(lg) {
      display: grid;
      grid-template-columns: minmax(0, 40%) minmax(0, 1fr);
      gap: 24px;
    }

    @include media(xl) {
      grid-template-columns: minmax(28.75%, 360px) minmax(0, 1fr);
    }
  }

  .stores_list {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 519px) {
      padding-left: 15px;
      padding-right: 15px !important;
    }

    @include media(sm) {
      flex: 1;
    }

    @include media(lg) {
      margin-top: 2rem;
    }

    &__mobile {
      margin-top: 2rem;
    }

    &__breadcrumb {
      margin-top: 0 !important;
      margin-bottom: 0.5rem !important;
      display: flex;
    }

    &__search_box {
      @extend .store_locator__search_box;
      display: none;

    }

    &__title_container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (min-width: 520px) and (max-width: 1024px) {
        margin-bottom: 2rem;
      }

      .title {
        font-size: 2rem;
        line-height: 2.75rem;
        font-weight: 700;

        @include media(md) {
          font-size: 2rem;
        }
      }

      .open_modal_btn {
        padding: 0;
        height: 40px !important;
        min-width: 40px !important;
        border-radius: $store-locator-input-border-radius - 2px !important;
        transition: $transition-all;

        @media screen and (min-width: 520px) {
          display: none;
        }

        svg {
          height: 20px;
          width: 20px;
        }
      }
    }

    &__container {
      display: none;

      @media screen and (min-width: 520px) {
        display: block;
      }
    }

    .store_placeholder {
      width: 100%;
      height: 320px;
      margin-bottom: 1.5rem;
    }

    &__content {
      &__filters {
        position: relative;

        &__button {
          border: 1px solid $gray-200;
          border-radius: $border-radius;
          display: flex;
          flex-direction: row;
          font-weight: 400;
          font-size: $text-base;
          margin-bottom: 24px;
          padding: 8px 12px 8px 16px;
          position: relative;
          width: 100%;

          span:first-child {
            justify-content: space-between;
            width: 100%;
          }
          div:first-child {
            justify-content: space-between;
            width: 100%;
          }
        }

        &__counter {
          align-items: center;
          background: $badge;
          border-radius: 50%;
          color: $white;
          display: flex;
          font-size: $text-xs;
          height: 16px;
          justify-content: center;
          position: absolute;
          right: 3px;
          top: 3px;
          width: 16px;
        }

        &__icon {
          align-self: center;
          border-left: 1px solid $gray-200;
          color: $secondary-400;
          font-size: 18px;
          font-weight: 600;
          padding-left: 10px;
        }

        &__options {
          background: $white;
          box-shadow: $box-shadow;
          height: 500px;
          overflow-y: auto;
          position: absolute;
          top: 48px;
          width: 100%;
          z-index: 3;
        }
      }
    }

    &__filters {
      &__button {
        background: transparent;
        color: $primary;
        text-decoration: underline;
        font-size: $text-sm;
        font-weight: 500;
        line-height: 18px;
        margin-bottom: 2rem;
        padding: 0;
        position: relative;
      }

      &__input {
        height: 40px !important;
        margin-bottom: 1rem;

        input {
          padding: 7px 16px !important;
        }

        input::placeholder {
          color: $secondary-400;
          font-size: $text-sm;
          line-height: 18px;
          font-weight: 400;
        }
      }

      &__title {
        height: 30px;
        font-weight: 500;
        font-size: $text-base;
        line-height: 24px;
      }
    }
  }

  .janestore_store-item__content-btn__wvfeJ {
    margin-bottom: 1rem;
  }

  .stores_map {
    height: 60vh;
    padding: 0 !important;
    border-radius: $store-locator-info-border-radius;

    @include media(sm) {
      height: 50vh;
    }

    @media screen and (min-width: 520px) and (max-width: 1024px) {
      height: 35vh;
    }

    @include media(lg) {
      height: 100%;
      flex: 3;
      margin-top: 2rem;
      position: sticky;
      top: 145px;
      z-index: 4;
    }

    div[class~='gm-style-iw-chr'] {
      display: none;
    }

    &__maps {
      height: 100%;
      width: 100%;   

      @media screen and (min-width: 520px) and (max-width: 1024px) {
        position: absolute !important;
        overflow: hidden;
        left: 0;
        height: 35vh !important;
      }

      @include media(lg){
        border-radius: $store-locator-info-border-radius;
      }   
    }
  }
}

.store_locator__search_box {
  position: relative;
  display: flex;
  flex-grow: 1;
  width: 100%;
  align-items: center;
  //margin-top: 16px;

  &_input {
    flex-grow: 1;
    border-radius: $store-locator-input-border-radius !important;
    border: 1px solid $store-locator-input-border-color !important;
    background-color: $store-locator-input-background !important;
    border-right: 0px;
    padding: 11px 48px 11px 0px;
    font-size: $text-base !important;

    &:hover {
      border: 1px solid $store-locator-input-border-color-activate !important;
    }

    &:focus-within {
      border: 1px solid $store-locator-input-border-color-activate !important;

      .input__label {
        color: $store-locator-input-border-color !important;
      }

      input::placeholder {
        color: $store-locator-input-placeholder-color !important;
      }
    }

    input[type='search'] {
      color: $store-locator-input-text-color !important;
    }

    input {
      .input__control {
        padding: 10px 54px 10px;
      }
    }
  }

  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    -webkit-user-modify: read-write !important;
    border-left: 1px solid $store-locator-input-separator-color !important;
    content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M13 3L3 13' stroke='%23" + $blog-header-input-clear-color + "' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
  }

  &_button {
    position: absolute;
    right: 4px;
    border-radius: $store-locator-input-border-radius - 2px !important;
    padding: 0 !important;
    height: 40px !important;
    width: 40px !important;
    color: $store-locator-button-icon-color !important;
    background: $store-locator-button-search-color !important;
    border: 1px solid $store-locator-button-search-color !important;

    &:not(:disabled):hover {
      color: $store-locator-button-icon-hover-color !important;
      background: $store-locator-button-search-hover-color !important;
      border: 1px solid $store-locator-button-search-hover-color !important;
    }

    &:not(:disabled):focus {
      color: $store-locator-button-icon-hover-color !important;
      background: $store-locator-button-search-hover-color !important;
      border: 1px solid $store-locator-button-search-hover-color !important;
    }
  }

  button {
    svg {
      * {
        stroke: $store-locator-button-icon-color;
      }
    }

    &:hover {
      svg {
        * {
          stroke: $store-locator-button-icon-hover-color;
        }
      }
    }
  }

  &_store_list {
    display: none;

    @media screen and (min-width: 520px) and (max-width: 1024px) {
      display: flex;
      max-width: 50%;
    }
  }

  &_filter_header {
    margin: 1.5rem 0;
    max-height: 40px;

    @media screen and (min-width: 520px) and (max-width: 1024px) {
      display: none;
    }
  }
}

.empty_locations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  min-width: 250px;
  width: 100%;
  text-align: center;
  border-radius: $store-locator-info-border-radius;

  .store_list_icon_search {
    font-size: $text-3xl;
    margin-bottom: 1rem;

    @include media(lg) {
      font-size: $text-4xl;
    }
  }

  .title {
    font-size: $text-base;
    font-weight: 700;
    margin-bottom: 0.5rem;

    @include media(lg) {
      font-size: $text-lg;
    }
  }

  .message {
    font-size: $text-xs;

    @include media(lg) {
      font-size: $text-sm;
    }
  }
}

.empty_locations {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 1rem;
  width: 100%;
  text-align: center;
  border-radius: $store-locator-info-border-radius;

  .store_list_icon_search {
    font-size: $text-3xl;
    margin-bottom: 1rem;

    @include media(lg) {
      font-size: $text-4xl;
    }
  }

  .title {
    font-size: $text-base;
    font-weight: 700;
    margin-bottom: 0.5rem;

    @include media(lg) {
      font-size: $text-lg;
    }
  }

  .message {
    font-size: $text-xs;

    @include media(lg) {
      font-size: $text-sm;
    }
  }
}

.stores_locator__stores {
  display: flex;
  background-color: inherit;
  flex-direction: column;
  height: calc(100vh - 160px);
  flex-grow: 1;
  padding-right: 8px;
  margin-right: -6px;
  gap: 12px;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $gray-200;
  }

  @media screen and (min-width: 520px) and (max-width: 1024px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: fit-content;
  }

  @include media(3xl) {
    height: calc(80vh - 160px);
  }

  &_empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 320px;

    @include media(lg) {
      max-height: 60vh;
    }
  }
}

.store_locator__modal {
  $space-x: 40px;
  $form-width: 446px;
  background: $store-locator-background-modal !important;

  @media screen and (min-width: 520px) {
    display: none;
  }

  > div {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    cursor: pointer !important;
    padding: 20px !important;

    > span {
      top: 0;
    }
  }
}
