.location_placeholder {
  &__banner {
    height: 60vw;
    max-height: 360px;
    width: 100%;

    @include media(lg) {
      max-height: 350px;
    }
  }

  &__content {
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-bottom: 32px;
      padding: 48px 0 0;

      @include media(lg) {
        gap: 32px;
        margin-bottom: 48px;
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      &__map {
        height: 200px;
        width: 100%;
        border-radius: $store-locator-info-border-radius;

        @include media(lg) {
          height: 100%;
        }
      }

      &__schedule {
        display: grid;
        gap: 26px;

        .schedule {
          display: flex;
          flex-direction: column;
          gap: 12px;

          @include media(sm) {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
          }

          &_item {
            height: 52px;
            width: 100%;
            border-radius: $store-locator-info-border-radius
          }
        }

        .label {
          height: 34px;
          width: 230px;
          margin-bottom: 12px;
          background: $placeholder-bg-200;
        }
      }
    }
  }

  &__sections {
    padding: 36px 0;
    .placeholder__related_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;

      .placeholder__related_title {
        width: 60%;
        max-width: 320px;
        height: 52px;
      }

      .placeholder__related_actions {
        width: 80px;
        height: 40px;
        border-radius: 30px;
      }
    }

    .placeholder__related_container {
      display: flex;
      overflow: hidden;
    }

    .placeholder__testimonial_card {
      width: 410px;
      padding: 24px;
      flex: 0 0 auto;
      height: auto;
      display: flex;
      flex-direction: column;

      .placeholder__name {
        width: 50%;
        height: 14px;
      }

      .placeholder__header {
        display: flex;
        align-items: center;
        width: 100%;
        padding-bottom: 16px;
        border-bottom: 1px solid $placeholder-bg;
        gap: 16px;

        .placeholder__figure {
          width: 58px !important;
          flex-shrink: 0;
          height: 58px;
          border-radius: 444px;
          background: $placeholder-bg-200;
        }
      }

      .placeholder__info {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 12px;
      }

      .placeholder__rating {
        display: flex;
        gap: 8px;

        i {
          color: $placeholder-bg;
          font-size: 24px;
          width: 24px;
          height: 24px;
        }
      }

      .placeholder__testimonial_text {
        padding-top: 16px;

        .placeholder__line {
           width: 100%;
           height: 14px;
        }

        .placeholder__line:nth-child(1) {
          width: 80%;
        }

        .placeholder__line:nth-child(2) {
          width: 90%;
        }

        .placeholder__line:nth-child(3) {
          width: 76%;
        }

        .placeholder__line:nth-child(4) {
          width: 64%;
        }

        .placeholder__line:nth-child(5) {
          width: 50%;
        }
      }

      .placeholder__testimonial_text_odd {
        .placeholder__line {
          width: 60%;
        }

        .placeholder__line:nth-child(1) {
          width: 90%;
        }

        .placeholder__line:nth-child(2) {
          width: 80%;
        }

        .placeholder__line:nth-child(3) {
          width: 70%;
        }

        .placeholder__line:nth-child(4) {
          width: 85%;
        }
      } 
    }

    .location_placeholder__category {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      row-gap: 8px;
      min-width: 100px;
      margin-right: 12px;

      @include media(lg) {
        min-width: 140px;
      }

      &_image,
      &_title {
        background-color: $placeholder-bg-200 !important;
        width: 100%;
      }

      &_image {
        border-radius: $border-radius;
        aspect-ratio: 1/1;
      }

      &_title {
        height: 14px;
        width: 70%;
      }
    }

    .location_placeholder__city {
      min-width: 288px;
      margin-right: 12px;

      @include media(lg) {
        min-width: 360px;
      }

      &_image {
        background-color: $placeholder-bg !important;
        width: 100%;
        padding-top: 66%;
        border-radius: $store-locator-info-border-radius;
      }

      &_title {
        height: 14px;
        width: 70%;
      }
    }

    .faq__title {
      height: 52px;
      margin-bottom: 40px;
      margin-right: auto;
      max-width: 478px;
      width: 100%;
    }

    .faq__content {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      @include media(lg) {
        column-gap: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      @include media(2xl) {
        column-gap: 48px;
      }


      &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        gap: 32px;
        padding-top: 8px;
        padding-bottom: 16px;
        border-bottom: 1px solid $placeholder-bg;
      }

      &__placeholder {
        height: 32px;
        border-radius: $store-locator-info-border-radius;
        width: 100%;
      }

      &__placeholder_icon {
        height: 36px;
        width: 36px !important;
        flex-shrink: 0;
        border-radius: 444px;
        background:$placeholder-bg-200;  
      }
    }

    .faq__content__item:nth-child(1) {
      .faq__content__placeholder {
        width: 80%;
      }
    }

    .faq__content__item:nth-child(2), .faq__content__item:nth-child(9) {
      .faq__content__placeholder {
        width: 90%;
      }
    }

    .faq__content__item:nth-child(3), .faq__content__item:nth-child(8) {
      .faq__content__placeholder {
        width: 86%;
      }
    }

    .faq__content__item:nth-child(4) {
      .faq__content__placeholder {
        width: 64%;
      }
    }

    .faq__content__item:nth-child(5) {
      .faq__content__placeholder {
        width: 50%;
      }
    }
  } 
}
