@import '../../../styles/variables';

.badge {
  padding: 4px 10px;
  border-radius: $border-radius-badge;
  background: $badge;
  color: $white;
  line-height: 20px;
  font-weight: 700;
  font-size: $text-xs;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}
