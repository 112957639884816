.blog-article {
  margin-bottom: 16px;

  @include media(md) {
    margin-bottom: 24px;
  }

  .blog-article__container {
    margin-right: auto;
    margin-left: auto;
    padding-top: 12px;

    @include media(lg) {
      padding-top: 18px;
      position: relative;
      display: grid;
      column-gap: 32px;
      grid-template-columns: minmax(0, 1fr) minmax(0, 360px);
    }

    @include media(xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 420px);
      column-gap: 42px;
    }

    @include media(2xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 420px);
      column-gap: 56px;
    }

    @include media(3xl) {
      grid-template-columns: minmax(0, 1fr) minmax(0, 500px);
      column-gap: px;
    }

    &_simple {
      grid-template-columns: 1fr;
    }

    .blog__article-meta {
      display: flex;
      margin-bottom: 36px;
      justify-content: space-between;
      width: 100%;
      align-items: center;

      @include media(xl) {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;
      }

      time {
        font-size: $text-sm;
        font-weight: 500;
        color: $gray-300;
        text-transform: uppercase;
      }
    }

    .blog-article__content {
      //margin-bottom: 3rem;

      h2,
      h3,
      h4 {
        font-weight: 700;
        margin-bottom: 16px;
        width: 100%;
        word-wrap: break-word;
      }

      h2 {
        font-size: $text-xl;
        line-height: 2rem;
      }

      h3 {
        font-size: 17px;
      }

      h4 {
        font-size: 15px;
      }

      p {
        margin-bottom: 25px;

        a {
          font-weight: 700;
          transition: color ease 0.2s;
          color: $primary-400;
        }

        a:hover {
          color: $primary-200;
        }
      }

      img {
        border-radius: $image-border-radius !important;
      }
    }

    .blog-article__feature-posts {
      display: none;

      @include media(lg){
        display: block;
      }

      &-title {
        font-size: $text-xl !important;
        margin-bottom: 24px;
      }

      &-body {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &-article {
        a {
          display: grid;
          grid-template-columns: minmax(0, 115px) minmax(0, 1fr);
          gap: 32px;
          width: 100%;
        }

        figure {
          width: 115px;
          height: 115px;
          padding-top: 0;
          border-radius: $image-border-radius;
        }

        h5 {
          order: 1;
          font-weight: 500;
          line-height: 28px;
        }

        time {
          order: 2;
        }

        p, div[class~='sharing_sharing__6DiP9'] {
          display: none;
        }

        div[class~='article_article__body__SUIxL'] {
          gap: 8px;
        }
        
      }
    }

    .article__date {
      time,
      p {
        font-size: $text-sm;
        font-weight: light;
        color: $gray-300;
        text-transform: uppercase;
      }
    }
  }
}

.blog {

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .blog__search_box {
    position: relative;
    display: flex;
    flex-grow: 1;
    width: 100%;
    align-items: center;
    margin-top: 16px;

    @include media(md) {
      order: 4;
      margin-top: 12px;
    }

    @include media(lg) {
      order: 3;
      min-width: 250px;
      max-width: 362px;
      margin: 0;
    }

    @include media(xl) {
      margin-left: auto;
      max-width: 336px;
    }

    @include media(2xl) {
      max-width: 396px;
    }

    @include media(3xl) {
      max-width: 582px;
    }

    &__input {
      flex-grow: 1;
      border-radius: $blog-header-input-border-radius !important;
      border: 1px solid $blog-header-input-border-color !important;
      background-color: $blog-header-input-background !important;
      border-right: 0px;
      padding: 11px 48px 11px 0px;
      font-size: $text-base !important;

      &:hover {
        border: 1px solid $blog-header-input-border-color-activate !important;
      }

      &:focus-within {
        border: 1px solid $blog-header-input-border-color-activate !important;

        .input__label {
          color: $blog-header-input-border-color !important;
        }

        input::placeholder {
          color: $blog-header-input-placeholder-color !important;
        }
      }

      input[type=search] {
        color: $blog-header-input-text-color !important;
      }

      input {
        .input__control {
          padding: 10px 54px 10px;
        }
      }
    }

    input::-webkit-search-cancel-button {
      -webkit-appearance: none;
      -webkit-user-modify: read-write !important;
      border-left: 1px solid $blog-header-input-separator-color !important;
      content: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 3L13 13M13 3L3 13' stroke='%23" + $blog-header-input-clear-color + "' stroke-width='1.6' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") !important;
    }

    &__button {
      position: absolute;
      right: 4px;
      border-radius: $blog-header-input-border-radius - 2px;
      padding: 0 !important;
      height: 40px !important;
      width: 40px !important;
      color: $blog-header-button-icon-color !important;
      background: $blog-header-button-search-color !important;
      border: 1px solid $blog-header-button-search-color !important;

      &:not(:disabled):hover {
        color: $blog-header-button-icon-hover-color !important;
        background: $blog-header-button-search-hover-color !important;
        border: 1px solid $blog-header-button-search-hover-color !important;
      }

      &:not(:disabled):focus {
        color: $blog-header-button-icon-hover-color !important;
        background: $blog-header-button-search-hover-color !important;
        border: 1px solid $blog-header-button-search-hover-color !important;
      }
    }

    button {
      svg {
        * {
          stroke: $blog-header-button-icon-color;
        }
      }

      &:hover {
        svg {
          * {
            stroke: $blog-header-button-icon-color;
          }
        }
      }
    }
  }
  
  h1 {
    font-size: $text-2xl;
    font-weight: 700;

    @include media(md) {
      font-size: 2rem;
    }

    @include media(3xl) {
      font-size: $text-4xl;
    }
  }

  .blog__description {
    padding-top: 24px;

    @include media(lg){
      padding-top: 40px;
    }
  }

  .blog__collection_title{
    padding-top: 24px;

    @include media(lg){
      padding-top: 40px;
    }
  }

  .blog__section.blog__section__grid {
    @include media(lg) {
      display: grid;
      grid-template-columns: 1fr 5fr;
      column-gap: 52px;
    }
  }
}


.blog__container_empty {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 450px;
}

.blog__empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 78px;
  padding-bottom: 108px;

  i {
    font-size: 1.875rem;
    color: $filter-icon-color;
    margin-bottom: 6px;
  }

  h5 {
    color: $secondary-400;
  }

  p {
    max-width: 333px;
  }
}

.placeholder__blog_page {
  padding-top: 40px;
  padding-bottom: 40px;

  .placeholder__blog_header {
    display: flex;
    flex-direction: column;   

    @include media(lg){
      flex-direction: row;
      justify-content: space-between;
    } 
  }

  .placeholder {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    border-radius: 4px;
    display: inline-block;
    background: $placeholder-bg;
  }

  .placeholder__blog_title, .placeholder__blog_search {
    width: 100%;
    height: 42px;
  }
  
  .placeholder__blog_title {
     @include media(md){
        width: 50%;
     }
  }

  .placeholder__blog_search {
     @extend .placeholder;

     @include media(md){
        width: 28%;
     }
  }

  h2, figure, h1, h5, p {
    @extend .placeholder;
  }

  h2 {
    width: 14%;
    height: 20px;
    margin-top: 24px;
    background-color: $placeholder-bg-100;
  }

  + h2 {
    width: 22%;
  }
  
  .placeholder__collection {
    display: grid;
    column-gap: 24px;
    height: max-content;
    row-gap: 16px;
    margin: 32px 0 64px;

    @media screen and (min-width: 460px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    } 

    @include media(lg) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media(2xl) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    @include media(3xl) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }
  } 
}
