.preferences {
  @extend .container;

  margin-bottom: 40px;

  @include media(md) {
    margin-bottom: 70px;
  }

  .alert {
    @include media(lg) {
      max-width: 588px;
    }

    @include media(xl) {
      max-width: 630px;
    }
  }

  .preferences__body {
    @include media(lg) {
      max-width: 588px;
      display: grid;
      grid-template-columns: 1fr;
    }

    @include media(xl) {
      max-width: 630px;
    }
  }

  .input_type_text {
    background: #f3f3f3;
    border: 1px solid #f3f3f3;
  }

  .heading.heading_level-1 {
    font-size: $text-2xl;
    font-weight: 600;
    margin-bottom: 30px;
  }

  .heading.heading_level-2 {
    font-size: $text-base;
    font-weight: 600;
  }

  .preferences__form-fieldset {
    margin-bottom: 25px;

    @include media(sm) {
      display: grid;
      grid-template-columns: 172px minmax(0, 1fr);
      gap: 16px;
      align-items: flex-start;
    }

    + .preferences__form-fieldset {
      border-top: 1px solid $gray-200;
      padding-top: 25px;
    }
  }

  .preferences__form-legend {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    width: 100%;

    @include media(sm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .preferences__link {
      font-size: $text-sm;
      color: $success !important;

      @include media(sm) {
        margin-top: 4px;
      }

      &:hover {
        color: $success !important;
      }
    }
  }

  .preferences__form-group {
    margin-bottom: 16px;
  }

  .preferences__two-cols {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 16px;
  }

  .preferences__optout-separator {
    border-top: 1px solid #f3f3f3;
  }

  .preferences__update-btn {
    background: white;
    margin: 40px -16px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    border-top: 1px solid $gray-200;
    padding: 16px;
    z-index: 1;

    @include media(sm) {
      margin: 40px 0 0;
    }

    @include media(lg) {
      max-width: 400px;
      position: static;
      margin-left: auto;
      margin-top: 15px;
      padding: 0;
      border: 0;
    }

    @include media(xl) {
      max-width: 442px;
    }

    .button {
      font-size: $text-sm;
      font-weight: 600;
      max-width: 252px;
      max-height: 36px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 4px 4px 20px #25ef7f70;
      color: #060907;
      text-transform: uppercase;
    }
  }
}
