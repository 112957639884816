.copyright-section {
  background: $gray-500;
}

.copyright {
  @extend .container;

  display: flex;
  flex-direction: column;
  row-gap: 16px;
  column-gap: 32px;
  justify-content: space-between;
  font-size: $text-sm;
  color: $color-text-footer;
}
