// Styles for brand page from here.
.brand__banner {
  background: $white;
  position: relative;
  padding: 0;

  @include media(sm) {
    padding-right: 15px;
    padding-left: 15px;
  }

  @include media(md) {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .banner__text {
    text-shadow: 0 0 17px rgba($white, 0.6);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @include media(md) {
      z-index: 1;
      display: grid;
      top: 38px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      position: absolute;
      transform: translate(0%, 0%);
      left: 0;
      margin-right: 20px;
      padding: 40px 40px;
      text-align: left;
    }

    @include media(lg) {
      top: 50px;
      column-gap: 70px;
    }

    @include media(xl) {
      top: 60px;
    }

    @include media(2xl) {
      top: 70px;
    }
  }

  figure {
    position: relative;
    height: 300px;
  }

  img {
    border-radius: 0;

    @include media(sm) {
      border-radius: $border-radius-md;
    }
  }

  .heading.heading_level-1 {
    font-weight: 700;
  }
}

// Styles for all brands page from here.
.brands-page {
  margin-top: 24px;
  margin-bottom: 120px;

  @include media(md) {
    display: grid;
    align-items: center;
    grid-template-columns: minmax(0, 1fr) auto;
  }

  .brands-page__filter {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    margin-top: 20px;

    @include media(md) {
      margin-top: 25px;
    }

    .input {
      border-radius: $border-radius-input 0 0 $border-radius-input;

      i {
        font-size: 24px;
      }
    }

    .button {
      border-radius: 0 $border-radius-input $border-radius-input 0;
      height: 56px;
      box-shadow: none;
      font-size: 14.7px;
      letter-spacing: 0.8px;

      span {
        font-size: $text-base;
        line-height: 24px;
      }
    }
  }

  .brands__description {
    margin-bottom: 30px;
    font-size: $text-xl;
  }

  .brands-page__list {
    margin-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 27px;

    @include media(sm) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 24px;
      row-gap: 43px;
    }

    @include media(md) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-column: 1 / -1;
    }

    @include media(lg) {
      grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    @include media(xl) {
      grid-template-columns: repeat(6, minmax(0, 1fr));
      column-gap: 24px;
    }

    @include media(2xl) {
      grid-template-columns: repeat(8, minmax(0, 1fr));
      column-gap: 24px;
    }

    &_empty {
      display: flex !important;
    }
  }

  .empty_results {
    height: 280px;
    display: flex;
    justify-content: center;
    align-self: center;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    max-width: 400px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 40px;

    i {
      font-size: $text-3xl;
    }
  }

  .brands-page__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;

    figure {
      margin-bottom: 8px;
      border-radius: $border-radius-md;
      box-shadow: 0px 0 12px rgb(18 37 33 / 12%);
      overflow: hidden;
      position: relative;
      width: 100%;
      aspect-ratio: 1/1;
    }

    span {
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: $text-base;
      line-height: 24px;
      color: $gray-500;
    }
  }

  .collection__load-more {
    margin-top: 56px;
    font-size: $text-lg;

    @include media(md) {
      grid-column: 1 / -1;
    }
  }
}
