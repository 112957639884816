@import './mixin';

.auth__container {
  background: $auth-background-color;
  background-image: $auth-background-image;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-block: 40px;
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .auth__logo {
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: $auth-logo-bottom-spacing;

    a {
      position: relative;
      flex-shrink: 0;
      width: $auth-logo-width;
      height: 100%;

      figure {
        margin-top: 0 !important;
        width: $auth-logo-width !important;
        aspect-ratio: unset !important;
        height: $auth-logo-heigth !important;

        img {
          height: auto;
          max-height: $auth-logo-heigth;

          @include media(md) {
            max-height: $auth-logo-heigth;
          }
        }
      }
    }
  }
}

.auth {
  margin: 0 auto;
  padding: 2rem;
  border-radius: $auth-border-radius-card !important;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: $auth-border-card;
  backdrop-filter: $auth-backdrop;
  background: $auth-background-card;

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $auth-headding-color;
  }

  small,
  span,
  p:not(.alert p, .auth_form__row > div p, .auth_form__column > div p) {
    color: $auth-text-color;
  }

  &.signup {
    padding: 2rem 1.5rem;
    max-width: 600px;

    @include media(sm) {
      padding: 2rem;
    }
  }

  &.verify {
    max-width: 380px;
    gap: 1rem;

    .heading.heading_level-2 {
      margin-bottom: 0rem;
    }

    .verify-info {
      font-size: $text-xs;
      line-height: 16px;

      br {
        @media screen and (max-width: 640px) {
          display: none;
        }
      }
    }

    .alert {
      margin-bottom: 0;
    }
  }

  .email_verification_link {
    margin-bottom: 1rem;

    button {
      font-weight: 700;
      color: get-value($label-btn-link, $auth-link-default);

      &:hover {
        color: $label-btn-link-hove;
      }
    }
  }

  .auth_form__row {
    display: grid;
    width: 100%;
    row-gap: 1rem;
    margin-bottom: 1rem;

    @include media(sm) {
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
    }
  }

  .auth_form__column {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    width: 100%;
  }

  .heading.heading_level-2 {
    margin-bottom: 2rem;
  }

  .auth__subscribe_chechbox {
    max-width: max-content;
    height: auto !important;
    width: auto;
  }

  .auth__agreement {
    display: block;
    align-self: flex-start;
    margin-top: 16px;
    font-size: 13px !important;

    a {
      font-weight: bolder;
      color: get-value($label-btn-link, $auth-link-default);

      &:hover {
        color: $label-btn-link-hove;
      }
    }
  }

  .input + .input,
  .input__feedback + .input {
    margin-top: 16px;
  }

  .auth__reset {
    margin-top: 16px;
    display: inline-block;

    button {
      font-weight: 700;
      font-size: $text-sm;
      color: get-value($label-btn-link, $auth-link-default);

      &:hover {
        color: $label-btn-link-hove;
      }
    }
  }

  .auth__action-main {
    display: block;
    width: 100%;
    text-transform: uppercase;
    margin: 25px auto 16px;
    // width: 300px;
  }

  .auth__action-alt {
    text-align: center;
    font-size: $text-sm;

    button {
      color: get-value($label-btn-link, $auth-link-default);
      padding-left: 2px;
      padding-right: 2px;
    }
  }
}

.auth__spinner_container {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;

  .spinner {
    color: $auth-spinner-color !important;
  }
}
