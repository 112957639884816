main {
  background-color: $background-theme;
}

.loading__general {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  min-height: 400px;

  .auth__loading {
    color: $auth-spinner-color !important;
  }
}

.loading__general::before {
  content: '';
  display: block;
  height: var(--header-height);
  background-color: $header-sticky-filled;
  transition: background-color 0.5s ease;
  width: 100%;
}

.spacing_top {
  height: var(--header-height);
  background-color: $header-sticky-filled;
} 

.page__top_spacing::before {
  content: '';
  display: block;
  height: var(--header-height);
  background-color: $header-sticky-filled;
  transition: background-color 0.5s ease;
  width: 100%;
}

.spacing_bottom {
  margin-bottom: $margin-section-mobile;

  @include media(lg) {
    margin-bottom: $margin-section;
  }
}

.padding-slice-small {
  padding-top: $padding-slice-mobile-small;
  padding-bottom: $padding-slice-mobile-small;

  @include media(lg) {
    padding-top: $padding-slice-desktop-small;
    padding-bottom: $padding-slice-desktop-small;
  }
}

.padding-slice-medium {
  padding-top: $padding-slice-mobile-medium;
  padding-bottom: $padding-slice-mobile-medium;

  @include media(lg) {
    padding-top: $padding-slice-desktop-medium;
    padding-bottom: $padding-slice-desktop-medium;
  }
}

.padding-slice-large {
  padding-top: $padding-slice-mobile-large;
  padding-bottom: $padding-slice-mobile-large;

  @include media(lg) {
    padding-top: $padding-slice-desktop-large;
    padding-bottom: $padding-slice-desktop-large;
  }
}

.page__not_found::before {
  content: '';
  display: block;
  height: var(--header-height);
  background-color: $header-sticky-filled;
  transition: background-color 0.5s ease;
  width: 100%;
}

// Background Image Section
.background__fixed_cover {
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.background__contained {
  background-attachment: scroll;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// Heading Font Size
.heading_fontSize__small {
  font-size: 18px !important;

  @include media(md) {
    font-size: 20px !important;
  }
}

.heading_fontSize__base {
  font-size: 20px !important;

  @include media(md) {
    font-size: 24px !important;
  }
}

.heading_fontSize__medium {
  font-size: 24px !important;

  @include media(md) {
    font-size: 28px !important;
  }
}

.heading_fontSize__large {
  font-size: 28px !important;

  @include media(md) {
    font-size: 36px !important;
  }
}

.heading_fontSize__xl {
  font-size: 32px !important;

  @include media(md) {
    font-size: 40px !important;
  }
}

// Spacing between product card in the product collections
.collections_small_spacing {
  grid-column-gap: 6px !important;

  @include media(sm) {
    grid-column-gap: 8px !important;
  }

  @include media(md) {
    grid-column-gap: 10px !important;
  }

  @include media(xl) {
    grid-column-gap: 14px !important;
  }

  @include media(2xl) {
    grid-column-gap: 14px !important;
  }
}

.collections_medium_spacing {
  grid-column-gap: 8px !important;

  @include media(sm) {
    grid-column-gap: 10px !important;
  }

  @include media(md) {
    grid-column-gap: 12px !important;
  }

  @include media(xl) {
    grid-column-gap: 16px !important;
  }

  @include media(2xl) {
    grid-row-gap: 32px !important;
  }
}

.collections_large_spacing {
  grid-column-gap: 8px !important;

  @include media(sm) {
    grid-column-gap: 12px !important;
  }

  @include media(md) {
    grid-column-gap: 16px !important;
  }

  @include media(xl) {
    grid-column-gap: 20px !important;
  }

  @include media(2xl) {
    grid-row-gap: 32px !important;
  }
}

.placeholder__page_banner {
  .placeholder__banner {
    position: relative;
    background: $placeholder-bg;
    padding-top: 58.13%;
    overflow: hidden;
    width: 100%;

    @include media(sm) {
      padding-top: 23.75%;
    }

    @include media(xl) {
      padding-top: 342px;
    }
  }

  @include media(md) {
    margin-bottom: 30px;
  }
}

.kiosk_placeholder__page_ecommerce {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .kiosk_placeholder__product_container {
    flex-grow: 1;
    flex-direction: column;
    padding-bottom: 4rem;

    .placeholder__product_list {
      display: grid;
      grid-column: 1 / -1;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: center;
      justify-content: center;
      justify-items: auto;
      width: 100%;
      column-gap: 14px;
      row-gap: 24px;

      @media screen and (min-width: 420px) {
        grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
      }

      @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      }

      @include media(lg) {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
    }

    .kiosk_product_placeholder {
      border: $kiosk-product-card-border;
      border-radius: $kiosk-card-border-radio;
      height: 290px !important;
    }

    .placeholder__pagination {
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 4rem;
      column-gap: 16px;
      max-width: 360px;

      .placeholder__container_pages {
        display: flex;
        column-gap: 16px;
        flex-grow: 1;

        .placeholder__page {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
      }

      .placeholder__actions {
        height: 16px;
        width: 30px;
        border-radius: 16px;
      }
    }
  }
}

.placeholder__page_ecommerce {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  @include media(md) {
    flex-direction: row;
    column-gap: 20px;
  }

  .placeholder__product_container {
    flex-grow: 1;
    flex-direction: column;
    padding-bottom: 4rem;

    .placeholder__product_list {
      display: grid;
      grid-column: 1 / -1;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      align-items: center;
      justify-content: center;
      justify-items: auto;
      width: 100;
      //column-gap: 20px;
      row-gap: 30px;

      @include media(sm) {
        grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      }

      @include media(md) {
        grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
      }

      &_card_large {
        @include media(sm) {
          grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
        }

        @include media(lg) {
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        }

        @include media(2xl) {
          grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
        }

        @include media(3xl) {
          grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
        }
      }
    }

    .placeholder__pagination {
      margin: 0 auto;
      display: flex;
      align-items: center;
      margin-top: 4rem;
      column-gap: 16px;
      max-width: 360px;

      .placeholder__container_pages {
        display: flex;
        column-gap: 16px;
        flex-grow: 1;

        .placeholder__page {
          height: 40px;
          width: 40px;
          border-radius: 50%;
        }
      }

      .placeholder__actions {
        height: 16px;
        width: 30px;
        border-radius: 16px;
      }
    }
  }
}

.deals__placeholder_product_list {
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  align-items: center;
  justify-content: center;
  justify-items: auto;
  padding-bottom: 4rem;
  width: 100;
  row-gap: 30px;

  @include media(sm) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    row-gap: 24px;
  }

  @include media(md) {
    grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
  }

  &_card_large {
    @include media(sm) {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
      grid-row-gap: 24px;
    }

    @include media(lg) {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    }

    @include media(2xl) {
      grid-row-gap: 36px;
      grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }

    @include media(3xl) {
      grid-template-columns: repeat(auto-fill, minmax(460px, 1fr));
    }
  }
}
