.side-nav {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  left: 0;
  pointer-events: none;
  user-select: none;

  $form-width: 384px;

  .side-nav__container {
    box-shadow: $box-shadow;
    transition: $transition-all;
    position: absolute;
    background: $background-side-nav;
    backdrop-filter: $side-nav-backdrop-filter;
    height: 100%;
    top: 0;
    left: -$form-width;
    width: 100%;
    width: calc(100% - 32px);
    max-width: $form-width;
    z-index: 1;
    pointer-events: initial;
    will-change: transform;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .side-nav__header {
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: $side-nav-border-header;
    background: $background-header-side-nav;
    box-shadow: $side-nav-shadow;
    backdrop-filter: $side-nav-backdrop-filter;

    i {
      color: $secondary-400;
    }

    .close {
      margin-left: auto;
    }
  }

  .side-nav__body {
    overflow-y: auto;
    padding: 31px 0;
    margin-right: 2px;

    &::-webkit-scrollbar {
      width: 4px;
      height: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $side-nav-scroll-color;
      border-radius: 8px;
    }

    .button_location {
      align-items: center;
      color: $side-nav-button-location-color;
      display: flex;
      gap: 12px;
      font-size: $text-base;
      font-weight: 400;
      line-height: 24px;
      margin: 24px;
      text-transform: uppercase;

      &:focus:not(:focus-visible) {
        outline: 0;
      }

      i {
        font-size: $text-xl;
        padding-right: 17px;
        color: $side-nav-icon-button-location-color;
      }

      &_image {
        position: relative;
        width: 24px;
        height: 24px;

        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }

  .side-nav__main {
    padding-bottom: 24px;
    border-bottom: $side-nav-border-main;
    &-heading {
      align-items: center;
      color: $side-nav-color;
      display: flex;
      font-size: $text-sm;
      font-weight: 400;
      line-height: 18px;
      padding: 0 24px 12px 24px;

      &:hover, &:focus-within, &:focus {
        color: $side-nav-color !important;
      }
    }

    .side-nav__item {
      align-items: center;
      color: $side-nav-item-color;
      cursor: pointer;
      display: flex;
      font-size: $text-base;
      font-weight: 700;
      justify-content: space-between;
      line-height: 24px;
      padding: 12px 24px;
      transition: $transition-all;
      //text-transform: uppercase;
      width: 100%;

      i {
        font-size: $text-2xl;
        padding: 6px;
        color: $side-nav-item-icon-color;
        font-weight: $side-nav-item-weigth-icon-color;
      }

      &:hover, &:focus-within, &:focus {
        color: $side-nav-active-color;
        background: $side-nav-active-bg;

        i{
          color: $side-nav-active-color;
        }
      }
    }

    .accordion {
      .accordion__body {
        display: flex;
        flex-direction: column;
        padding: 0 24px;

        .inmediate_child {
          align-items: center;
          color: $side-nav-subitem-color;
          display: flex;
          font-size: $text-base;
          font-weight: 500;
          line-height: 24px;
          padding: 12px 0px 12px 24px;
          margin: 0px -24px;

          &:hover, &:focus-within, &:focus {
            background: $side-nav-active-bg;
            color: $side-nav-active-color;
          }
        }

        .secondary_child {
          align-items: center;
          color: $side-nav-color;
          display: flex;
          font-size: $text-base;
          font-weight: 400;
          line-height: 24px;
          padding: 12px 0px 12px 36px;
          margin: 0px -24px;

          &:hover, &:focus-within, &:focus {
            background: $side-nav-active-bg;
            color: $side-nav-active-color;
          }
        }
      }
    }
  }

 .logo {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: $side-nav-logo-max-height;
    width: 100%;
    max-width: $side-nav-logo-max-width;

    figure {
      position: relative;
      height: auto;
      width: 100%;
        
      img {
        max-height: $side-nav-logo-max-height;
      }
    }
  }

  .side-nav__item {
    &.side-nav__item_active {
      color: $side-nav-active-color;

      .icon {
        color: $side-nav-active-color;
      }
    }

    &:active {
      background: $side-nav-active-bg;
    }
  }

  .side-nav__overlap {
    background: $overlay-bg;
    height: 100%;
    width: 100%;
    cursor: pointer;
    transition: $transition-all;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
  }

  &.side-nav_open {
    .side-nav__container {
      left: 0;
    }

    .side-nav__overlap {
      visibility: visible;
      opacity: 1;
      pointer-events: initial;
    }
  }
}
