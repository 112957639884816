@import './variables';
@import './mixin';

.thanku_section {
  padding-bottom: 32px;
  padding-top: 32px;

  @include media(sm) {
    padding-bottom: 64px;
    padding-top: 64px;
    max-width: 743px !important;
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    position: relative;

    svg {
      width: 244px;
      height: 200px;

      @include media(sm) {
        width: 294px;
        height: 240px;
      }

      path[class='path-shadow'] {
        fill: $thanku-picture-path-shadow;
      }

      path[class='path-shadow-line'] {
        fill: $thanku-picture-path-shadow-line;
      }

      path[class='path-popper-bands'] {
        fill: $thanku-picture-path-popper-bands;
      }

      path[class='path-popper-body'] {
        fill: $thanku-picture-path-popper-body;
      }

      path[class='path-popper-hole'] {
        fill: $thanku-picture-path-popper-hole;
      }

      path[class='path-popper-hole-shadow'] {
        fill: $thanku-picture-path-popper-hole-shadow;
      }

      path[class='path-particle-group-1'] {
        fill: $thanku-picture-path-particle-group-1;
      }

      path[class='path-particle-group-2'] {
        fill: $thanku-picture-path-particle-group-2;
      }

      path[class='path-particle-group-3'] {
        fill: $thanku-picture-path-particle-group-3;
      }
      path[class='path-particle-group-4'] {
        fill: $thanku-picture-path-particle-group-4;
      }
    }

    @include media(sm) {
      height: 240px;
    }
  }

  &__description {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;

    &__title {
      color: $thanku-banner-title;
      font-size: $text-4xl !important;
      font-weight: 500;
      line-height: 44px;
      padding: 16px 0 12px;
      letter-spacing: -0.1rem;

      @include media(sm) {
        font-size: 50px !important;
        line-height: 60px;
      }
    }

    &__text {
      align-items: center;
      color: $thanku-banner-text;
      display: flex;
      font-size: $text-lg;
      font-weight: 400;
      line-height: 25px;
      max-width: 880px;
    }
  }
}

.thanku_content__container {
  margin-top: 32px;
  display: flex;
  flex-direction: column;

  @include media(sm) {
    margin-top: 40px;
  }

  .order_detail {
    color: $thanku-banner-text;
    font-size: $text-base;
    font-weight: 500;

    .order_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 12px;

      .order__btn_cancel {
        padding: 0px;
        text-decoration: underline dotted;
        text-underline-offset: 4px;
        height: 24px;
      }
    }

    &__title {
      color: $thanku-order-detail-title;
      font-size: $text-xl;
      line-height: 24px;
      font-weight: 500;
    }

    &__subtitle {
      margin: 20px 0 12px;
      line-height: 20px;
      font-size: $text-base;
      font-weight: 600;
      color: $thanku-order-detail-title;
    }

    &__status {
      margin-top: 12px;
      display: flex;

      .status-element {
        text-align: center;
        flex: 1;

        .status-icon {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: $thanku-status-icon-bg;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 8px auto;

          &.active {
            background-color: $thanku-status-active-icon-bg;
          }

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .status-label {
          font-size: $text-xs;
          color: $thanku-status-label-color;
          font-weight: 500;

          &.active {
            color: $thanku-status-active-label-color;
            font-weight: 500;
          }
        }

        .status-date {
          font-size: $text-xs;
          color: $thanku-status-label-color;
        }
      }

      .status-separator {
        display: flex;
        align-items: center;
        gap: 8px;

        @include media(sm) {
          gap: 24px;
        }

        .separator-dot {
          width: 4px;
          height: 4px;
          background-color: $thanku-status-separator-dot-color;
          border-radius: 50%;

          &.active {
            background-color: $thanku-status-active-separator-dot-color;
          }
        }
      }
    }

    &__description {
      padding: 12px 0 16px;

      @include media(sm) {
        padding: 12px 0 32px;
      }

      p {
        padding-bottom: 8px;
      }
    }

    &__map--flex {
      align-items: center;
      color: $gray-500;
      font-size: $text-base;
      font-weight: 400;
      line-height: 24px;

      &__location {
        display: flex;
        flex-direction: row;
        padding: 16px 0;
      }

      &__title {
        color: $thanku-order-detail-title;
        font-size: $text-2xl !important;
        font-weight: 500;
        line-height: 36px;
        padding-bottom: 24px;
      }

      .location_icon {
        align-self: center;
        font-size: $text-2xl;
        margin-right: 16px;
      }
    }

    &__order_number {
      color: $secondary-400;
      font-size: $text-xl;
      font-weight: 700;
      line-height: 24px;
    }

    &__refresh_button {
      display: none;
      margin-top: 48px;
      max-width: 284px;

      @include media(sm) {
        display: block;
      }
    }
  }

  .order_items {
    &__title {
      align-items: center;
      color: $thanku-order-detail-title;
      display: flex;
      font-size: $text-xl;
      font-weight: 500;
      margin: 16px 0 12px;
    }

    &__products {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 16px;

      .product {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
        padding: 12px;

        .product_image {
          position: relative;
          flex-shrink: 0;
          width: 72px;
          height: 72px;

          @media screen and (max-width: 427px) {
            align-self: flex-start;
          }
        }

        .product_info {
          flex-grow: 1;
          display: flex;
          gap: 12px;

          @media screen and (max-width: 427px) {
            flex-direction: column;
          }
        }

        .description {
          align-items: center;
          color: $thanku-banner-text;
          font-size: $text-sm;
          font-weight: 400;
          line-height: 18px;
          flex: 1;

          &__tags {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 8px;

            @include media(sm){
              margin-bottom: 12px;
            }

            .flower__type {
              height: 24px;
              width: fit-content;
              min-width: 24px;
              display: flex;
              line-height: 14px;
              justify-content: center;
              align-items: center;
              padding: 0 10px;
              border-radius: $border-radius-badge;
              font-size: $text-xs;
              cursor: default;

              strong {
                text-transform: uppercase;
              }
            }

            $flower_types: (
              'default' $flower-type-default-bg-color $flower-type-default-color,
              'sativa' $flower-type-sativa-bg-color $flower-type-sativa-color,
              'indica' $flower-type-indica-bg-color $flower-type-indica-color,
              'hybrid' $flower-type-hybrid-bg-color $flower-type-hybrid-color,
              'vape' $flower-type-vape-bg-color $flower-type-vape-color,
              'extract' $flower-type-extract-bg-color $flower-type-extract-color,
              'si' $flower-type-si-bg-color $flower-type-si-color,
              'is' $flower-type-is-bg-color $flower-type-is-color
            );

            @each $name, $bg, $color in $flower_types {
              .type__#{$name} {
                // border: 1px solid $color !important;
                background-color: $bg !important;
                color: $color !important;
              }
            }
          }

          &__tag {
            display: flex;
            align-items: center;
            background: $thanku-product-tag-bg;
            padding: 4px 8px;
            color: $thanku-product-tag-color;
            border-radius: 4px;
            padding-bottom: 0.25rem;
            font-size: 10px;
            font-weight: 700;
            line-height: 16px;
            letter-spacing: 0.03em;
            text-align: left;
          }

          &__name {
            color: $thanku-product-name-color;
            font-size: $text-base;
            line-height: 20px;
            font-weight: 500;

            @media screen and (max-width: 427px) {
              font-size: $text-sm;
              line-height: 18px;
            }
          }
        }

        .description__subcategory {
          @media screen and (max-width: 480px) {
            display: none;
          }
        } 
      }

      .price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;

        @media screen and (max-width: 427px) {
          flex-direction: row;
          justify-content: space-between;

          .product__quantity {
            order: 1;
          }

          p  {
            order:2;
          }
        }

        .product__quantity {
          background-color: $thanku-product-qty-bg;
          color: $thanku-product-qty-color;
          padding: 2px 8px;
          height: 20px;
          width: 29px;
          border-radius: 444px;
          font-weight: 700;
          font-size: 10px;
          font-weight: 700;
          letter-spacing: 0.03em;
          text-align: center;
        

          @media screen and (min-width: 428px) {
            align-self: flex-end;
          }
        }

        p {
          //text-align: left;
          flex-direction: row;
          justify-content: end;
          flex-wrap: wrap;
          align-items: center;
          max-width: 100px;
          column-gap: 6px;

          del {
            font-size: $text-sm;
            color: $discount-price;
            line-height: 18px;
            text-align: right;
          }
          ins {
            font-size: $text-base;
            font-weight: 700;
            line-height: 20px;
            letter-spacing: 0.01em;
            text-align: right;
          }

          @media screen and (max-width: 428px) {
           
          }
        }
      }
    }

    .more_details__to_pay {
      font-size: $text-2xl;
      font-weight: 700;
    }
  }

  .order_details {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .element {
      display: flex;
      justify-content: space-between;

      p,
      span {
        color: $thanku-order-details-color;
        font-size: $text-base;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-align: left;
      }
    }

    .total-paid {
      padding-top: 4px;
      margin-bottom: 16px;

      p,
      span {
        color: $thanku-order-details-total-paid-color;
        font-size: $text-base;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-align: left;
      }
    }

    .billing {
      &__section {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 216px;
        gap: 32px;

        @include media(sm) {
          flex-direction: row;
        }
      }

      &__content {
        width: 100%;
        
        p {
          line-height: 24px;
        }

        @include media(sm) {
          width: 50%;
        }
      }

      &__map {
        position: relative;
        width: 100%;
        height: 180px;
        margin-bottom: 16px;
        border-radius: 8px;
        overflow: hidden;

        @include media(sm) {
          width: 50%;
          height: auto;
          margin-bottom: 0px;
        }

        .map__element {
          height: 100%;
          width: 100%;
        }

        figure {
          height: 100%;
        }
      }

      &__item {
        color: $thanku-billing-item-color;
        font-size: $text-base;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.01em;
        text-align: left;

        @include media(sm) {
          line-height: 24px;
        }
      }

      &__payment_type {
        display: flex;
        gap: 4px;
        width: fit-content;
        padding: 8px;
        border-radius: 8px;
        background-color: $thanku-billing-payment-type-bg;
        color: $thanku-billing-payment-type-color;
      }

      &__space {
        margin-bottom: 16px;

        @include media(sm) {
          margin-bottom: 24px;
        }
      }

      &__upper_space {
        margin-top: 16px;

        @include media(sm) {
          margin-top: 24px;
        }
      }
    }
  }
}

.notes {
  &__body {
    font-size: $text-base;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 16px;
  }

  &__confirmation {
    font-size: $text-xs;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-align: left;
    margin-bottom: 16px;
  }

  &__link {
    color: $thanku-notes-link-color;
    text-decoration: underline;
  }
}

.order_detail__action {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;

  @media screen and (min-width: 560px) { 
    flex-direction: row-reverse;
    justify-content: flex-start;

    .action_button, .secondary_action_button {
      width: 240px !important;
    }

    .secondary_action_button {
      width: 160px !important;
    }
  }
}
