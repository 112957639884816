@import '../../../styles/variables';

.heading {
  font-family: var(--heading-font-family) !important;
  color: $heading-color;
  font-weight: 700;

  &.heading_level-1 {
    font-size: $text-2xl;

    @include media(md) {
      font-size: 2rem;
    }

    @include media(3xl) {
      font-size: $text-4xl;
    }
  }

  &.heading_level-2 {
    font-size: $text-xl;

    @include media(md) {
      font-size: $text-2xl;
    }

    @include media(3xl) {
      font-size: $text-3xl;
    }
  }

  &.heading_level-3 {
    font-size: calc(1.3rem + 0.6vw);
    color: $black;
  }

  &.heading_level-4 {
    font-size: calc(1.275rem + 0.3vw);

    @include media(xl) {
      font-size: 1.5rem;
    }
  }

  &.heading_level-5 {
    font-size: 1.25rem;
  }

  &.heading_level-6 {
    font-size: 1rem;
  }
}

.heading__description {
  max-width: 480px;
}
