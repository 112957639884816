.socials {
  line-height: 1;
  font-size: $text-2xl;

  > * + * {
    margin-left: 25px;
  }

  > * {
    display: inline-block;
    transition: $transition-all;

    &:hover {
      opacity: $opacity;
    }
  }
}

.contact-page__socials {
  max-width: fit-content;
  padding: 0 5px;
  border: 1px solid $primary-100;
  border-radius: $border-radius-md;

  .socials__item {
    padding: 10px 10px;
  }

  > * + * {
    margin-left: 5px;

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 25px;
      background-color: $primary-100;
      transform: translateX(-13px);
    }
  }
}
