@import '../../../styles/variables';

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100vw;
  z-index: 15;

  .modal__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  &.auth_overlay {
    .modal__overlay {
      background-color: $primary !important;
      opacity: 1 !important;
    }
  }

  .image__overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  .modal__content {
    border-radius: $border-radius;
    box-shadow: $box-shadow;
    padding: 16px;
    margin: 16px;
    position: relative;
    width: calc(100vw - 32px);
    max-width: 690px;

    > .close {
      position: absolute;
      right: 16px;
      top: 12px;
      z-index: 1;
    }
  }

  &.modal_sm .modal__content {
    max-width: 373px;
  }

  &.modal_md .modal__content {
    max-width: 420px;
  }

  &.modal_lg .modal__content {
    max-width: 1500px;
  }

  .modal__body {
    background: inherit;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
    padding: 0.5rem 0.5rem;
    scroll-behavior: smooth;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}
