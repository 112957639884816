.order-details {
  margin: 0 auto;
  width: 100%;
  margin-bottom: 80px;

  @media screen and (max-width: 480px) {
    padding-right: 15px;
    padding-left: 15px;
  }

  .back_button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 1rem;
    font-weight: 700;

    i {
      font-size: $text-lg;
    }
  }

  /*   .heading.heading_level-2 {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 3.5rem;
  }

  .order__num-order {
    padding-top: 2.5rem;

    p {
      padding-bottom: 0.5rem;
    }
  } */

  .order__number {
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0.75rem 0;
  }

  .order__btn_cancel {
    margin-top: 1rem;

    @include media(lg) {
      margin-top: 0;
      position: absolute;
      top: 40px;
      right: 0;
      max-width: 200px;
    }
  }
}

.orders {
  position: relative;
  /* align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center; */
  width: 100%;

  @media screen and (max-width: 480px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  .orders__list_blanck {
    margin: 24px 0;

    .orders__link {
      &:hover {
        color: $primary-400;
      }
    }
  }

  .orders__history-loading,
  .orders__history-blank {
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .orders__tabs_list {
    .orders__separator {
      position: absolute;
      border-bottom: 0.5px solid $gray-100;
      z-index: 2;
      width: 100%;
      left: 0;
    }

    button {
      position: relative;
      color: $gray-600;
      border: 0px;
      padding: 16px 16px;
      font-weight: 500;
      width: 120px;
      transition: all 0.3s ease;
      white-space: nowrap;
      line-height: 1;
      outline: 0;

      &.order__tab_active {
        color: $tab-btn-general;
        background: $white;
      }

      &.order__tab_active::before {
        content: '';
        width: 64px;
        justify-content: center;
        border-bottom: 4px solid $tab-btn-general;
        position: absolute;
        bottom: 0;
        left: 28px;
      }

      &:hover {
        color: $tab-btn-general;
      }
    }
  }

  .heading.heading_level-2 {
    /*     font-size: $text-4xl;
    font-weight: 600; */
    margin-bottom: 40px;
    text-align: center;
  }

  .orders__link {
    font-weight: 700;
    transition: $transition-all;

    &:hover {
      color: $gray-300;
    }
  }

  .order__btn-detail {
    align-self: flex-start;
  }

  .order__status {
    display: flex;
    align-items: flex-start;
    align-self: center;

    @include media(md) {
      margin-bottom: 8px;
    }

    .badge {
      background: $gray-500;
      margin-left: 16px;

      .icon {
        margin-right: 2px;
      }
    }

    &.order__status_red {
      .badge {
        background: $errors;
      }
    }
  }

  .orders__item {
    display: grid;
    grid-template-columns: minmax(0, 1fr) auto;
    gap: 16px;

    @include media(md) {
      row-gap: 0;
    }

    + .orders__item {
      margin-top: 25px;
      padding-top: 25px;
    }

    &:last-child {
      margin-bottom: 64px;
    }

    .link {
      color: $gray-500;
      font-size: $text-sm;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .order__description {
    color: $gray-500;
    line-height: 1.7;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    align-items: flex-start;
    grid-column: 1 / -1;
    font-size: $text-sm;
  }

  .orders__actions-icon {
    font-size: 28px;
    color: $gray-200;

    &:hover {
      color: $secondary-400;
    }
  }

  .popover__actions {
    background: $white;
    border-radius: $border-radius;
    border: 0.5px solid $gray-100;
    box-shadow: $box-shadow-2;
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 140px;
    padding: 12px 0px;
    position: absolute;
    transform: translateY(10px) translateX(25%);
    user-select: none;
    z-index: 3;

    @include media(sm) {
      transform: translateY(10px) translateX(-110px);
    }
  }

  .actions__link {
    display: flex;
    align-items: center;
    font-size: $text-xs;
    font-weight: 500;
    letter-spacing: -0.46px;
    text-decoration: underline;

    span {
      font-weight: 400;
      font-size: $text-base;
    }
  }

  .orders__status {
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: flex-end;

    .status__ellipsy {
      width: 8px;
      height: 8px;
      border-radius: $border-radius-full;
    }

    .status__name {
      text-transform: capitalize;
    }

    @include media(md) {
      justify-content: flex-start;
    }
  }

  .peak {
    background: $white;
    box-shadow: -10px 0px 12px rgba(186, 186, 186, 0.3);
    height: 12px;
    position: absolute;
    width: 12px;
    top: -7px;
    right: 9px;
    border-top: 0.5px solid $gray-100;
    border-left: 0.5px solid $gray-100;
    transform: rotateZ(45deg);
  }

  table {
    border-collapse: collapse;
    text-align: left;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  table tr {
    color: $gray-600;
    border-top: 1px solid $primary;
    display: flex;
  }

  td {
    display: flex;
    flex-direction: column;

    .cell_label {
      letter-spacing: -0.67px;
      margin-bottom: 0.5rem;

      @include media(md) {
        height: 30px;
      }
    }

    .cell_value {
      font-weight: 700;
      font-size: $text-xs;
      letter-spacing: -0.46px;
      text-transform: uppercase;
    }
  }

  td:first-child {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  td:first-child {
    width: 16%;
  }

  td:nth-child(2) {
    width: 14%;
  }

  td:nth-child(3) {
    width: 38%;
  }

  td:nth-child(4) {
    width: 16%;
  }

  th td:nth-child(5) {
    width: 16%;
  }

  table th,
  table td {
    padding: 1.5rem 0.5rem 1.5rem 1rem;
    text-align: left;
    font-size: 1rem;
    font-weight: 400;

    span {
      font-weight: 700;
      color: $gray-600;
      font-size: $text-sm;
    }
  }

  table th {
    font-size: 1rem;
    font-weight: 400;
    color: $gray-500;
  }

  @media screen and (max-width: 768px) {
    table {
      border: 0;
      width: 100%;
    }

    table thead {
      display: none;
    }

    table tr {
      display: block;
      padding: 1.75rem 0;
    }

    td:first-child,
    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4),
    td:nth-child(5),
    td:nth-child(6),
    td:nth-child(7) {
      width: 100%;
      text-align: right;
      display: grid;
      grid-template-columns: 1fr 2fr;
      gap: 1rem;
      font-size: 1rem;
      padding: 0.5rem 0;

      .cell_label,
      .cell_value {
        text-align: left;
        text-align: left;
      }
    }
    td:nth-child(8) {
      display: none;
    }

    table td:last-child {
      border-bottom: 0;
    }

    .orders__btn-actions {
      display: flex !important;
      flex-direction: row;
      width: 100%;
      padding: 1rem 0.5rem 2rem 1rem;

      .cell_label {
        display: none;

        @include media(md) {
          display: block;
        }
      }

      .actions__link {
        width: 100%;
        font-size: $text-xs;
        font-weight: 500;
      }
    }
  }
}

.order {
  @extend .container;

  margin-bottom: 40px;

  @include media(md) {
    margin-bottom: 70px;
  }

  .heading.heading_level-1 {
    font-size: $text-2xl;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .heading.heading_level-2 {
    font-size: $text-base;

    &:first-child {
      border-top: 0;
      padding-top: 0;
    }
  }

  .order__content {
    @include media(md) {
      display: grid;
      grid-template-columns: 275px auto;
      column-gap: 25px;
    }
  }

  .order__refresh-status {
    margin-left: auto;
  }

  .map {
    height: 150px;
    border-radius: $border-radius;
    overflow: hidden;
    margin-bottom: 25px;

    @include media(md) {
      height: 100%;
      border-left: 1px solid $gray-200;
      border-radius: 0;
      grid-column: 2;
      grid-row: 1 / 5;
    }
  }
}
