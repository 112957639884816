.alert {
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  width: 100%;
  padding: 12px;
  gap: 8px;
  font-weight: 400;
  font-size: $text-xs;
  line-height: 20px;
  letter-spacing: 0.01px;
  margin-bottom: 16px;
  border-radius: $alert-border-radius;

  &_success {
    background: $alert-success-color;
    color: $alert-text-success-color;
    border: 1px solid $alert-success-border-color;

    svg {
      * {
        stroke: $alert-success-icons-color;
      }
    }
  }

  &_error {
    background: $alert-errors-color;
    color: $alert-text-errors-color;
    border: 1px solid $alert-errors-border-color;

    svg {
      * {
        stroke: $alert-errors-icons-color;
      }
    }
  }

  &_warning {
    background: $alert-warning-color;
    color: $alert-text-warning-color;
    border: 1px solid $alert-warning-border-color;

    svg {
      * {
        stroke: $alert-warning-icons-color;
      }
    }
  }

  &_info {
    background: $alert-info-color;
    color: $alert-text-info-color;
    border: 1px solid $alert-info-border-color;

    svg {
      * {
        stroke: $alert-info-icons-color;
      }
    }
  }

  &_layout_icon {
    grid-template-columns: 20px 1fr;
  }

  &_layout_action {
    grid-template-columns: 1fr 20px;
  }

  &_layout_clasic {
    grid-template-columns: 20px 1fr 20px;
  }

  figure {
    padding: 1.5px;

    svg {
      width: 17px;
      height: 17px;
    }
  }

  .floating {
    position: absolute;
    right: 10px; 

    &--none {
      display: none;
    }
  }

  a {
    color: $label-btn-link;
    font-weight: 500;

    &:hover {
      color: $label-btn-link-hove;
    }
  }

  button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    width: 20px;
    height: 20px;
    line-height: 1;
    outline: 0;
    background: transparent;

    svg {
      width: 14px;
      height: 14px;
    }
  }
}
