.special_page {
  &__content {
    align-items: stretch;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: center;
    padding-bottom: 120px;
    width: 100%;

    @include media(sm) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      grid-gap: 24px;
    }

    &--hide {
      display: none;
    }

    .special_product {
      max-width: 236px;
    }
  }
}

.special_empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: center;
  padding-top: 78px;
  padding-bottom: 108px;
  user-select: none;

  &__actions {
    column-gap: 5px;
    display: flex;
    flex-direction: row;

    .action {
      text-decoration: underline;
    }
  }

  h5 {
    color: $primary-400;
    font-size: $text-xl !important;
  }
}
