.account {
  padding-bottom: 60px;

  @media screen and (max-width: 480px) {
    padding-left: 0;
    padding-right: 0;
  }

  @include media(md) {
    padding-top: 80px;
  }

  /*  .heading.heading_level-2 {
    font-weight: 500 !important;
    font-size: 32px !important;
    line-height: 38px !important;
    letter-spacing: -1.67px !important;

    @include media(md) {
      max-width: none;
      font-size: 40px !important;
      letter-spacing: -2.5px !important;
    }
  } */

  .heading.heading_level-6 {
    text-transform: none !important;
  }

  .account__first-image-container {
    min-width: 210px;
    @include media(lg) {
      min-width: 215px;
    }
  }

  .account_wrapper-content-container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include media(md) {
      display: grid;
      gap: 3rem;
      grid-template-columns: minmax(0, 180px) 4fr;
    }

    @include media(lg) {
      gap: 4rem;
      max-width: 90%;
      margin: 0 auto;
    }

    @include media(xl) {
      gap: 6rem;
      max-width: 75%;
    }

    .account_views {
      display: flex;
      position: sticky;
      position: -webkit-sticky;
      overflow: unset;
      justify-content: center;
      background-color: $background-theme;
      z-index: 4;
      column-gap: 24px;
      row-gap: 12px;
      padding: 30px 0;
      top: 150px;

      @include media(sm) {
        top: 113px;
      }

      @include media(md) {
        height: 290px;
        padding-top: 73px;
        flex-direction: column;
        align-self: flex-start;
        justify-content: flex-start;
      }

      .account_views__item {
        position: relative;
        font-size: $text-base;
        line-height: 26px;
        letter-spacing: 0.6px;

        font-weight: 600;

        @include media(md) {
          align-items: center;
          font-size: $text-base;
          line-height: 20px;
          letter-spacing: 1px;
          padding: 10px 0;
          width: fit-content;
          text-transform: uppercase;
          transition: all 0.25s ease-in;
        }
      }

      .active::after {
        content: '';
        position: absolute;
        width: 90%;
        height: 4px;
        bottom: -2px;
        left: 5%;
        border-bottom: 3px solid $account-tap-color;
      }
    }

    .account_content {
      position: relative;
      display: flex;
      align-items: center;

      .auth__messagge_loggin {
        display: flex;
        margin: 0 auto;
        align-items: center;
        gap: 24px;
        border-radius: 30px;
        border: 1px solid $account-message-border-color;
        padding: 24px;
        max-width: 380px;
        max-height: 158px;

        @media screen and (max-width: 400px) {
          flex-direction: column;
          max-width: 300px;
          max-height: 210px;
        }

        .auth__messagge_body {
          span {
            font-size: $text-xl;
            font-weight: 800;
            line-height: 26px;
          }

          p {
            font-size: $text-sm;
            margin-top: 4px;
            text-align: left;
            color: $account-message-heading-color !important;

            @media screen and (max-width: 400px) {
              max-width: 200px;
              text-align: left;
            }
          }
        }

        button {
          height: 36px;
          padding: 6px 30px;
          border-radius: 30px;

          > div {
            font-size: $text-sm;
            line-height: $text-sm;
          }
        }
      }

      .wishlist__container_account {
        .wishlist__blank_container {
          min-height: 112px;
          margin-bottom: 0;
        }
      }
    }
  }

  .account__user-name-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    flex-grow: 2;

    @include media(lg) {
      max-width: 280px;
    }

    @include media(xl) {
      justify-content: flex-start;
    }

    @include media(2xl) {
      max-width: 350px;
    }

    @include media(3xl) {
      max-width: 480px;
    }
  }

  .account__user_input-name {
    font-weight: 700;
    font-size: $text-2xl;
    line-height: 36px;
    color: $account-photo-username;
    text-align: center;

    @include media(md) {
      text-align: left;
    }

    @include media(lg) {
      text-align: center;
    }
  }

  .account__user-span-location-text {
    color: $account-photo-direction;
    display: flex;
    flex-wrap: wrap;
    font-size: $text-xl;

    @include media(lg) {
      justify-content: center;
    }
  }

  .account__auth-loading {
    height: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    margin: 0 auto;
  }

  .account__auth-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 100%;

    @media screen and (max-width: 768px) {
      width: 85%;
    }

    @media screen and (max-width: 400px) {
      width: 92%;
      max-width: 380px;
    }

    > div:nth-child(1) {
      @include media(sm) {
        margin-left: 64px;
        margin-right: 64px;
      }
    }

    .account__auth_title {
      margin-bottom: 40px !important;
      text-align: center;
    }

    .alert {
      margin-bottom: 3rem !important;
    }

    .account__update-footer {
      background: $background-theme;
      //margin: 40px -15px 0;
      display: flex;
      align-items: center;
      position: sticky;
      position: -webkit-sticky;
      bottom: 0;
      padding: 36px 0;
      z-index: 3;

      @include media(sm) {
        margin: 40px 0 0;
        width: 100%;
      }

      @include media(lg) {
        display: flex;
        width: 100%;
        position: relative;
        margin-top: 0px;
        padding: 0;
        border: 0;
      }
    }
  }

  .account__body {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 0px 48px 0px;
    row-gap: 48px;

    @include media(lg) {
      grid-template-columns: 1fr 1fr;
      padding-bottom: 48px;
      column-gap: 10%;
    }
  }

  .prueba {
    padding-top: 15px;
  }

  .account__form-fieldset {
    display: block;
  }

  .account__form-title {
    color: $gray-600 !important;
  }

  .account__form-body {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    padding-top: 12px;
  }

  .account__form-group {
    margin-bottom: 24px;
  }

  .account__signout-btn {
    margin-top: 2rem;
    max-width: 220px;
  }

  .account__reset-password {
    text-transform: uppercase;
    height: 53px;

    @include media(md) {
      width: fit-content;
    }

    @include media(lg) {
      width: 100%;
    }

    @include media(xl) {
      width: fit-content;
    }
  }

  // section 2 account
  .account__address {
    display: flex;
    flex-direction: column;
    padding: 48px 0px;

    address {
      max-width: 420px;
    }
  }

  .account__product-preference-container {
    display: flex;
    min-width: 100px;
  }

  .input_type_text {
    background: $gray-200;
    border: 0.5px solid $gray-200;
  }

  .account__flex-col-container {
    display: flex;
    flex-direction: column;
    padding-top: 12px;
    justify-content: center;
    gap: 24px;
    flex-grow: 1;

    p {
      color: $gray-500;
    }
  }

  .account__flex-row-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    width: 100%;
    gap: 30px;

    @include media(md) {
      flex-direction: row;
      gap: 80px;
    }
  }

  // section 3 account
  .account__identification-container {
    @extend .account__address;
    width: 100%;

    h6 {
      padding-bottom: 1rem;
    }
  }

  .account__identification-container:last-of-type {
    border-bottom: 0px;
    padding-bottom: 0px;
  }

  .account__identification-container-photo {
    @extend .account__address;
  }

  .account__form-legend {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    row-gap: 16px;


    @include media(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      max-width: unset;
      column-gap: 10%;
    }
  }

  .identification_images_container {
    padding-top: 36px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 24px;
    width: 100%;
    align-items: center;

    @include media(md) {
      width: 50%;
    }
  }

  .account_product_preference {
    @extend .account__address;
    border-bottom: 0px;

    @include media(md) {
      max-width: 50%;
      border-top: 0;
    }
  }

  .licence_number_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    margin-top: 10px;
    width: 100%;
  }

  .identification_description {
    padding: 12px 0px 32px;
    color: $gray-500;
    font-size: $text-sm;
    font-weight: 400;
  }

  .identification_expiration_date {
    margin-top: 12px;
  }

  .account__prefer-use-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include media(md) {
      justify-content: flex-start;
    }
  }

  .account__selfi-container {
    @include media(md) {
      margin-left: 20px;
    }
  }

  .heading.heading_level-4 {
    font-weight: 500;
    color: $gray-600;
  }

  .account__add-address-icon {
    margin-right: 5px;
    font-size: $text-xl;
  }

  .account__add-address-btn {
    max-width: 220px;
  }

  .account__update-btn {
    @extend .account__reset-password;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 480px) {
      max-width: 250px;
    }
  }

  .info {
    display: inline-block;
    padding: 10px 0px;
    font-size: 80%;
  }

  .input__feedback {
    color: $errors;
  }
}

.account__address_modal {
  div[class~='modal__content'] {
    background-color: $white !important;
  }
}
