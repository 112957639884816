.collection-section {
  background: $background-color-collection;
  padding: 70px 0;

  .collection__products {
    margin-top: 45px;
  }

  .heading__description {
    font-size: $text-lg;
    font-weight: 400;
  }

  .filters__container {
    &::after {
      background: linear-gradient(
        to left,
        $background-color-collection 50%,
        rgba($background-color-collection, 0)
      );
    }

    &::before {
      background: linear-gradient(
        to right,
        $background-color-collection 50%,
        rgba($background-color-collection, 0)
      );
    }
  }
}

.collection__products {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  column-gap: 15px;
  row-gap: 24px;

  .placeholder.product {
    height: 312px;
    background-color: $background-placeholder-product;
  }

  @include media(sm) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media(md) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  @include media(lg) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }

  @include media(xl) {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}

.collection__load-more {
  display: flex;
  align-items: center;
  margin: 64px auto 0;
  text-transform: uppercase;
  font-size: $text-xl;
  font-weight: 600;
  transition: $transition-all;

  &:disabled {
    opacity: 0.3;
  }

  .spinner {
    margin-right: 11px;
  }
}

.collection__banner {
  background: $white;
  position: relative;
  padding-left: 0;
  padding-right: 0;

  .collection__banner_desktop {
    display: none;
  }

  .collection__banner_mobile {
    display: block;
  }

  @include media(sm) {
    padding-left: 15px;
    padding-right: 15px;

    .collection__banner_desktop {
      display: block;
    }

    .collection__banner_mobile {
      display: none;
    }
  }

  @include media(md) {
    padding-top: 40px;
    padding-bottom: 0px;
  }

  .collection__banner_text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    text-align: center;

    @include media(sm) {
      text-align: left;
    }

    @include media(md) {
      z-index: 1;
      display: grid;
      top: 75px;
      left: 0;
      transform: translate(0%, 0%);
      grid-template-columns: repeat(2, minmax(0, 1fr));
      padding: 40px 40px;
    }

    @include media(lg) {
      top: 80px;
      column-gap: 70px;
    }

    @include media(xl) {
      top: 90px;
    }

    @include media(2xl) {
      top: 100px;
    }
  }

  figure {
    position: relative;
    height: 300px;
  }

  img {
    border-radius: 0;

    @include media(sm) {
      border-radius: $border-radius-md;
    }
  }

  .heading.heading_level-1 {
    font-weight: 700;
  }
}

.collection {
  margin-bottom: 120px;

  .heading__description {
    margin-top: 16px;
    padding-top: 5.5px;
  }

  &__category-description {
    padding-bottom: 20px;
  }
}

.colection_no-products {
  margin-top: 45px;
  margin-bottom: -45px;
}

.collection__products {
  margin-top: 45px;
}
