@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spinner {
  display: flex;
  align-items: center;

  .spinner__animation {
    border: 2px solid currentColor;
    border-top-color: transparent;
    border-radius: 50%;
    display: inline-block;
    height: 24px;
    width: 24px;
    animation: spin 1s linear infinite;
  }

  &.spinner_sm .spinner__animation {
    height: 14px;
    width: 14px;
  }

  .spinner__label {
    margin-left: 13px;
  }
}
